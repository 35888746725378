import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './ResultsPage.css';

function ResultsPage() {
  const location = useLocation();
  const navigate = useNavigate();
  const { activities } = location.state;

  const handleReviewClick = () => {
    navigate('/review');
  };

  return (
    <div className="results-container">
      <h2>Your Date Night Activities</h2>
      {activities.date_night_activities.map((activity, index) => (
        <div key={index} className="activity-card">
          <h3>{activity.activity}</h3>
          <p>{activity.description}</p>
          <p><strong>Cute Mission:</strong> {activity.cute_mission}</p>
          <p><strong>Daring Mission:</strong> {activity.daring_mission}</p>
          <p><strong>Romantic Mission:</strong> {activity.romantic_mission}</p>
        </div>
      ))}
      <button className="btn-review" onClick={handleReviewClick}>
        Leave a Review
      </button>
    </div>
  );
}

export default ResultsPage;