import React, { useState } from "react";
import "./ReviewPage.css";
import axios from "axios";

function ReviewPage() {
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");

  const [reviewStatus, setReviewState] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Review Submitted:", { rating, comment });
    try {
      await axios.post(
        "https://doolbetaserver.onrender.com/dool/api/v1/userFeedback/reviews",
        { star: rating, comment },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      setReviewState("Submitted! Thank you so much!");
    } catch (error) {
      console.error("Error fetching userFeedback/reviews", error);
      setReviewState("Oops Something Went Wrong. Try Agian Later!");
    }
  };

  return (
    <div className="review-container">
      <h2>Leave a Review</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label>Rating:</label>
          <select
            value={rating}
            onChange={(e) => setRating(e.target.value)}
            required
          >
            <option value="0">Select</option>
            <option value="1">1 Star</option>
            <option value="2">2 Stars</option>
            <option value="3">3 Stars</option>
            <option value="4">4 Stars</option>
            <option value="5">5 Stars</option>
          </select>
        </div>
        <div>
          <label>Comment:</label>
          <textarea
            value={comment}
            onChange={(e) => setComment(e.target.value)}
            required
          />
        </div>
        <button className="btn-submit" type="submit" disabled={reviewStatus}>
          Submit Review
        </button>
        <p>{reviewStatus}</p>
      </form>
    </div>
  );
}

export default ReviewPage;
