import React from 'react';
import { useNavigate } from 'react-router-dom';
import './HomePage.css';
// import PersonalizedActivitiesImg from '../assets/personalized_activities.png';
// import UniqueMissionsImg from '../assets/unique_missions.png';
import CoverPhoto from '../assets/coverphoto.jpg';

function HomePage() {
  const navigate = useNavigate();

  const handlePlanDateClick = () => {
    navigate('/preferences');
  };

  return (
    <div className="home-container">
        <div className='main-container'>
      <h1>Welcome to Doolisu Beta</h1>
      <p>Plan your FREE perfect date night with personalized activities and missions.</p>
      <button className="btn-plan" onClick={handlePlanDateClick}>
        Plan My Date Night
      </button>
                <img src={CoverPhoto} alt="Cover" className='cover-image'/>

      </div>
      <div className="info-sections">
        <section className="info-section">
          {/* <img src={PersonalizedActivitiesImg} alt="Personalized Activities" /> */}
          <div>
            <h2>Personalized Activities</h2>
            <p>Get date ideas tailored to your interests, budget, and activity level.</p>
          </div>
        </section>
        <section className="info-section">
          {/* <img src={UniqueMissionsImg} alt="Unique Missions" /> */}
          <div>
            <h2>Unique Missions</h2>
            <p>Each activity comes with cute, daring, and romantic missions to make your date night special.</p>
          </div>
        </section>
        <section className="info-section most-right">
          {/* <img src={EasyToUseImg} alt="Easy to Use" /> */}
          <div>
            <h2>Easy to Use</h2>
            <p>Simply input your preferences and get instant date night suggestions for a memorable evening.</p>
          </div>
        </section>
      </div>
    </div>
  );
}

export default HomePage;