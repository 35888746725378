import React, { useState, useEffect } from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import "./PreferencesPage.css";

function PreferencesPage() {
  const [email, setEmail] = useState("");
  const [preferences, setPreferences] = useState({
    activity_level: "",
    budget: "",
    romantic_gauge: "",
    location: "",
  });
  const [loading, setLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    // Generate and store device ID if it doesn't exist
    if (!localStorage.getItem("device_id")) {
      localStorage.setItem("device_id", uuidv4());
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setPreferences({ ...preferences, [name]: value });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setLoading(true);
    const device_id = localStorage.getItem("device_id");
    try {
      console.log({ ...preferences });

      await axios.post(
        "https://doolbetaserver.onrender.com/dool/api/v1/userFeedback/emails",
        { email },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      const response = await axios.post(
        "https://doolbetaserver.onrender.com/dool/api/v1/activity/dateplans",
        { device_id, ...preferences },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );
      navigate("/results", { state: { activities: response.data.activities } });
    } catch (error) {
      console.error("Error fetching date activities", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <div className="preferences-container">
      <h2>Enter Your Email to Start Planning</h2>
      <form onSubmit={handleSubmit}>
        <input
          type="email"
          placeholder="Email"
          value={email}
          onChange={handleEmailChange}
          required
        />
        <h2>Enter Your Preferences</h2>
        <div>
          <label>Activity Level:</label>
          <select name="activity_level" onChange={handleInputChange} required>
            <option value="">Select</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>
        <div>
          <label>Budget:</label>
          <select name="budget" onChange={handleInputChange} required>
            <option value="">Select</option>
            <option value="low">Low</option>
            <option value="medium">Medium</option>
            <option value="high">High</option>
          </select>
        </div>
        <div>
          <label>Romantic Gauge:</label>
          <select name="romantic_gauge" onChange={handleInputChange} required>
            <option value="">Select</option>
            <option value="chill">Chill</option>
            <option value="romantic">Romantic</option>
            <option value="super romantic">Super Romantic</option>
          </select>
        </div>
        <div>
          <label>Location:</label>
          <input
            type="text"
            name="location"
            placeholder="ex: San Francisco, CA. Los Angeles, CA"
            onChange={handleInputChange}
            required
          />
        </div>
        <button className="btn-submit" type="submit" disabled={loading}>
          {loading ? (
            <>
              <div className="spinner"></div>
            </>
          ) : (
            "Get Date Ideas"
          )}
        </button>
      </form>
    </div>
  );
}

export default PreferencesPage;
